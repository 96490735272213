import React, { useState } from "react";
import { Link } from "react-router-dom";
import { WhitelistModal } from "./Modal";

function Hero() {
  const [open, setOpen] = useState(false)
  const handleWhiteList = () => {
    setOpen(true)
  }
  const title='Get Whitelisted';
  const body='We will start Whitelisting soon. Please join our Discord to stay updated on when it starts.';

  return (
      <section className="relative py-16 2xl:py-32 overflow-hidden hero-section bg-gray-600">
        <div className="container px-3 mx-auto">
          <WhitelistModal open={open} setOpen={setOpen} title={title} body={body} buttonText='Ok'></WhitelistModal>
          <div className="max-w-3xl mx-auto text-center -mt-16">
            <span className="text-lg font-bold text-green-600">Sonat Clan NFT Drop</span>
            <h2 className="mb-8 text-5xl font-bold font-heading text-white">The hottest blockchain <br/> game is coming</h2>
            <div className="mx-auto">
              <p className="mb-10 text-lg text-gray-100 font-bold">NFT for intense gaming</p>
              <Link onClick={handleWhiteList} className="inline-block mb-4 sm:mb-0 sm:mr-4 py-5 px-12 text-white font-bold rounded-full transition duration-200 bg-green-600 hover:bg-green-700" to="/">Get Whitelisted</Link>
              <Link className="inline-block py-5 px-12 text-white font-bold border border-gray-300 hover:border-gray-200 rounded-full" to="/whitepaper">Read Whitepaper</Link>
            </div>
          </div>

          <div className="flex justify-center mt-8">
            <a className="flex justify-center items-center w-12 h-12 mr-4 bg-gray-800 rounded-full" href="https://discord.gg/ADk2VUx78p" target="_blank" rel="noreferrer">
              <svg width="25" height="55" viewBox="0 0 71 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0)">
                  <path d="M60.1045 4.8978C55.5792 2.8214 50.7265 1.2916 45.6527 0.41542C45.5603 0.39851 45.468 0.440769 45.4204 0.525289C44.7963 1.6353 44.105 3.0834 43.6209 4.2216C38.1637 3.4046 32.7345 3.4046 27.3892 4.2216C26.905 3.0581 26.1886 1.6353 25.5617 0.525289C25.5141 0.443589 25.4218 0.40133 25.3294 0.41542C20.2584 1.2888 15.4057 2.8186 10.8776 4.8978C10.8384 4.9147 10.8048 4.9429 10.7825 4.9795C1.57795 18.7309 -0.943561 32.1443 0.293408 45.3914C0.299005 45.4562 0.335386 45.5182 0.385761 45.5576C6.45866 50.0174 12.3413 52.7249 18.1147 54.5195C18.2071 54.5477 18.305 54.5139 18.3638 54.4378C19.7295 52.5728 20.9469 50.6063 21.9907 48.5383C22.0523 48.4172 21.9935 48.2735 21.8676 48.2256C19.9366 47.4931 18.0979 46.6 16.3292 45.5858C16.1893 45.5041 16.1781 45.304 16.3068 45.2082C16.679 44.9293 17.0513 44.6391 17.4067 44.3461C17.471 44.2926 17.5606 44.2813 17.6362 44.3151C29.2558 49.6202 41.8354 49.6202 53.3179 44.3151C53.3935 44.2785 53.4831 44.2898 53.5502 44.3433C53.9057 44.6363 54.2779 44.9293 54.6529 45.2082C54.7816 45.304 54.7732 45.5041 54.6333 45.5858C52.8646 46.6197 51.0259 47.4931 49.0921 48.2228C48.9662 48.2707 48.9102 48.4172 48.9718 48.5383C50.038 50.6034 51.2554 52.5699 52.5959 54.435C52.6519 54.5139 52.7526 54.5477 52.845 54.5195C58.6464 52.7249 64.529 50.0174 70.6019 45.5576C70.6551 45.5182 70.6887 45.459 70.6943 45.3942C72.1747 30.0791 68.2147 16.7757 60.1968 4.9823C60.1772 4.9429 60.1437 4.9147 60.1045 4.8978ZM23.7259 37.3253C20.2276 37.3253 17.3451 34.1136 17.3451 30.1693C17.3451 26.225 20.1717 23.0133 23.7259 23.0133C27.308 23.0133 30.1626 26.2532 30.1066 30.1693C30.1066 34.1136 27.28 37.3253 23.7259 37.3253ZM47.3178 37.3253C43.8196 37.3253 40.9371 34.1136 40.9371 30.1693C40.9371 26.225 43.7636 23.0133 47.3178 23.0133C50.9 23.0133 53.7545 26.2532 53.6986 30.1693C53.6986 34.1136 50.9 37.3253 47.3178 37.3253Z" fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="71" height="55" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <a className="flex justify-center items-center w-12 h-12 bg-gray-800 rounded-full" href="https://twitter.com/TheSonatClan" target="_blank" rel="noreferrer">
              <svg className="w-4 h-4 text-white" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 2.41887C17.3306 2.7125 16.6174 2.90712 15.8737 3.00162C16.6388 2.54487 17.2226 1.82712 17.4971 0.962C16.7839 1.38725 15.9964 1.68763 15.1571 1.85525C14.4799 1.13413 13.5146 0.6875 12.4616 0.6875C10.4186 0.6875 8.77387 2.34575 8.77387 4.37863C8.77387 4.67113 8.79862 4.95237 8.85938 5.22012C5.7915 5.0705 3.07687 3.60013 1.25325 1.36025C0.934875 1.91263 0.748125 2.54488 0.748125 3.2255C0.748125 4.5035 1.40625 5.63637 2.38725 6.29225C1.79437 6.281 1.21275 6.10888 0.72 5.83775C0.72 5.849 0.72 5.86363 0.72 5.87825C0.72 7.6715 1.99912 9.161 3.6765 9.50412C3.37612 9.58625 3.04875 9.62562 2.709 9.62562C2.47275 9.62562 2.23425 9.61213 2.01038 9.56262C2.4885 11.024 3.84525 12.0984 5.4585 12.1332C4.203 13.1154 2.60888 13.7071 0.883125 13.7071C0.5805 13.7071 0.29025 13.6936 0 13.6565C1.63462 14.7106 3.57188 15.3125 5.661 15.3125C12.4515 15.3125 16.164 9.6875 16.164 4.81175C16.164 4.64862 16.1584 4.49113 16.1505 4.33475C16.8829 3.815 17.4982 3.16587 18 2.41887Z" fill="white"></path>
              </svg>
            </a>
          </div>
        </div>
      </section>
  );
}

export default Hero;
