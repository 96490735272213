import React from "react";
import Navbar from '../Components/Navbar';
import Footer from "../Components/Footer";
import Group from '../assets/sonat-group.jpg';
import HeadShot from '../assets/sonat-headshots.gif';

function Intro() {

  return (
    <div>
      <Navbar />

      <img className="w-full h-120 object-cover" src={Group} alt="" />
      <section className="py-20 2xl:py-20 bg-gray-800 overflow-hidden white-paper-wrapper">
        <div className="container px-4 mx-auto">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="mt-7 mb-14 text-5xl text-white font-bold font-heading">Sonat Clan Metaverse Whitepaper</h2>
            <p className="max-w-xl mx-auto mb-14 text-xl text-gray-200"><i>Don’t sell, until you get a life-changing offer you cannot refuse.</i></p>
            <div className="inline-flex pt-14 mb-14 items-center border-t border-gray-500">
              <img className="mr-8 w-20 lg:w-24 h-20 lg:h-24 rounded-full" src={HeadShot} alt="" />
              <div className="text-left">
                <h4 className="mb-1 text-2xl font-bold text-gray-50">Sonat Team</h4>
              </div>
            </div>
            <div className="mb-6 max-w-xl mx-auto text-center">
              <h2 className="mt-12 text-3xl text-white font-bold font-heading">OVERVIEW</h2>
            </div>
            <div className="max-w-full mx-auto">
              <p className="mb-6 text-left text-xl text-gray-200">The Sonat Clan club is the first truly deflationary NFT set, the first club where holders possess the power to not only increase the rarity of their collection over time but can also earn passive income from their precious NFTs. Every character is a randomly generated work of art, unique from all others. The entire set is divided into two, an initial 5000 would be released on mint day to whitelisted addresses, and the other 5000 (called the higher-rare-stars) would be unlocked and minted at certain periods only by the holders of the first 5000.</p>
              <p className="mb-6 text-left text-xl text-gray-200">The characters, based on medieval warriors from another dimension from earth would be the same characters in our upcoming game – Sonat World (See Road map), where the characters would battle enemy forces from an evil lord.</p>
            </div>
          </div>

          <div className="max-w-4xl mx-auto text-center">
            <div className="mb-6 max-w-xl mx-auto text-center">
              <h2 className="mt-12 text-3xl text-white font-bold font-heading" id="story">THE STORY</h2>
            </div>
            <div className="max-w-full mx-auto">
              <p className="mb-6 text-left text-xl text-gray-200">TThe Sonat clan is a group of peace loving but formidable people, each member is gifted from birth with certain traits and powers which in a way differentiates them from each other, and they spend their entire lives developing these abilities.</p>
              <p className="mb-6 text-left text-xl text-gray-200">A thousand years ago, an evil lord from a distant land invaded the Sonat people and hoped to conquer them. Mighty heroes from the clan fought a fierce battle to defend their home with the help of certain elders of the land who decided to sacrifice themselves so the clan could defeat the evil lord and his armies by putting themselves in a deep sleep forever till the evil lord is defeated. While in this deep sleep, their strength and powers would augment other clan members into far superior and formidable warriors. Warriors would continue to sacrifice themselves for each other and enter this deep sleep a ceremony called <strong>‘the ascension’</strong> until finally the ascension of a warrior leads to the emergence of the chosen one – the one who has all the powers and strength to defeat the evil lord completely.</p>
            </div>
          </div>

          <div className="max-w-4xl mx-auto m-20 overflow-x-auto overflow-y-hidden text-hidden">
            <div className="mb-6 max-w-xl mx-auto text-center">
              <h2 className="text-2xl text-white font-bold font-heading" id="story">Warriors Characters and Traits</h2>
            </div>
            <table className="table-auto w-full">
              <thead className="bg-green-600">
                <tr className="text-lg text-white text-left"><th className="pl-28 py-8 font-normal">Character</th><th className="pl-14 font-normal">Traits and powers</th></tr>
              </thead>
              <tbody className="bg-gray-900">
                <tr className="border-b border-gray-500">
                  <td className="w-1/2 py-10 pl-40 text-left border-r border-gray-500">
                    <div className="flex items-center">
                      <h4 className="text-xl text-white">Kyberos (25%)</h4>
                    </div>
                  </td>
                  <td className="w-1/2 pl-14 px-4 text-lg text-gray-200">Master combatant - can launch multiple attacks very rapidly and all at once to overwhelm the enemy (attack centric)</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="w-1/2 py-10 pl-40 text-left border-r border-gray-500">
                    <div className="flex items-center">
                      <h4 className="text-xl text-white">Badger (25%)</h4>
                    </div>
                  </td>
                  <td className="w-1/2 pl-14 px-4 text-lg text-gray-200">Highly resistant to attacks -- Can absorb attacks long enough to deliver kill shots (defense centric)</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="w-1/2 py-10 pl-40 text-left border-r border-gray-500">
                    <div className="flex items-center">
                      <h4 className="text-xl text-white">Philisic (14%)</h4>
                    </div>
                  </td>
                  <td className="w-1/2 pl-14 px-4 text-lg text-gray-200">Skilled in knives and darts - launches long range attacks - very effective against enemies that are highly combative like the Kyberos, but weak against enemies akin to the badger</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="w-1/2 py-10 pl-40 text-left border-r border-gray-500">
                    <div className="flex items-center">
                      <h4 className="text-xl text-white">Mentalis (14%)</h4>
                    </div>
                  </td>
                  <td className="w-1/2 pl-14 px-4 text-lg text-gray-200">Highly cerebral, finds a way to win (A cheat code in the game flashes to reveal what the character should do in each situation, if there's anything that could be done). Has a little of everything except magical powers</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="w-1/2 py-10 pl-40 text-left border-r border-gray-500">
                    <div className="flex items-center">
                      <h4 className="text-xl text-white">Sinus (8%)</h4>
                    </div>
                  </td>
                  <td className="w-1/2 pl-14 px-4 text-lg text-gray-200">Sorceress - cast a spell to weaken enemy, can suck out the life force of some not so strong enemies to death and can also heal up. Physically they are weakest of them all. Only a sorcerers can resist another sorceress' attacks</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="w-1/2 py-10 pl-40 text-left border-r border-gray-500">
                    <div className="flex items-center">
                      <h4 className="text-xl text-white">Timasia (8%)</h4>
                    </div>
                  </td>
                  <td className="w-1/2 pl-14 px-4 text-lg text-gray-200">Can stop time completely, or slow time down depending on how susceptible the enemy is, hence, can immobilize the enemy and attack them to death. Only a Timasia can resist another Timasia’s attacks</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="w-1/2 py-10 pl-40 text-left border-r border-gray-500">
                    <div className="flex items-center">
                      <h4 className="text-xl text-white">Varinths (5%)</h4>
                    </div>
                  </td>
                  <td className="w-1/2 pl-14 px-4 text-lg text-gray-200">Combination of two different powers into one character, one human, and one superhuman. Quite rare (e.g., Sinus and a Mentalist)</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="w-1/2 py-10 pl-40 text-left border-r border-gray-500">
                    <div className="flex items-center">
                      <h4 className="text-xl text-white">Super Varinths (1%)</h4>
                    </div>
                  </td>
                  <td className="w-1/2 pl-14 px-4 text-lg text-gray-200">Combination of three different powers into one character (two human and one superhuman. Very rare (e.g., Sonat, Badger and Philisic all in one)</td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="w-1/2 py-10 pl-40 text-left border-r border-gray-500">
                    <div className="flex items-center">
                      <h4 className="text-xl text-white">The chosen</h4>
                    </div>
                  </td>
                  <td className="w-1/2 pl-14 px-4 text-lg text-gray-200">Combines all powers and the only one that can defeat the evil lord. Only one exist.</td>
                </tr>
              </tbody>
            </table>

            <div className="max-w-full mx-auto">
              <p className="mt-10 mb-6 text-left text-xl text-gray-200">Every character is born with these traits; and these traits have different rarity as seen below:</p>
            </div>


            <table className="table-auto w-full">
              <thead className="bg-green-600">
                <tr className="text-lg text-white text-center"><th className="pl-5 py-8 font-normal">Kyberos</th><th className="font-normal">Badger</th><th className="font-normal">Philisic</th><th className="font-normal">Mentalis</th><th className="font-normal">Mentalis</th><th className="font-normal">Sinus</th><th className="font-normal">Timasia</th><th className="font-normal">S. Varinth</th><th className="font-normal">Total</th></tr>
              </thead>
              <tbody className="bg-gray-900">
                <tr className="border-b border-gray-500">
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">25.0%</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">25.0%</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">14.0%</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">14.0%</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">8.0%</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">8.0%</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">5.0%</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">1.0%</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">100.0%</p>
                  </td>
                </tr>
                <tr className="border-b border-gray-500">
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">1250.0</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">1250.0</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">700.0</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">700.0</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">400.0</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">400.0</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">250.0</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">50.0</p>
                  </td>
                  <td className="py-10 text-center border-r border-gray-500">
                    <p className="text-xl text-white">5,000</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="max-w-full mx-auto">
              <p className="mt-10 mb-6 text-left text-xl text-gray-200">The varinths and Super Varinths are also broken down as shown in the tables below:</p>
            </div>

            <div className="flex flex-wrap">
              <div className="w-1/2">
                <div className="p-5 bg-gray-800">

                  <table className="table-auto w-full">
                    <thead className="bg-green-600">
                      <tr className="text-lg text-white text-center"><th className="py-8 font-normal">Varinths</th><th className="font-normal">Amount</th></tr>
                    </thead>
                    <tbody className="bg-gray-900">
                      <tr className="border-b border-gray-500">
                        <td className="w-1/2 border-r border-gray-500">
                          <div className="text-center py-5">
                            <p className="text-xl text-white">Kyberos/Sinus</p>
                          </div>
                        </td>
                        <td className="text-lg text-center text-gray-200">50</td>
                      </tr>
                      <tr className="border-b border-gray-500">
                        <td className="w-1/2 border-r border-gray-500">
                          <div className="text-center py-5">
                            <h4 className="text-xl text-white">Badger/Sinus</h4>
                          </div>
                        </td>
                        <td className="text-lg text-center text-gray-200">50</td>
                      </tr>
                      <tr className="border-b border-gray-500">
                        <td className="w-1/2 border-r border-gray-500">
                          <div className="text-center py-5">
                            <h4 className="text-xl text-white">Kyberos/Timasia</h4>
                          </div>
                        </td>
                        <td className="text-lg text-center text-gray-200">50</td>
                      </tr>
                      <tr className="border-b border-gray-500">
                        <td className="w-1/2 border-r border-gray-500">
                          <div className="text-center py-5">
                            <h4 className="text-xl text-white">Badger/Timasia</h4>
                          </div>
                        </td>
                        <td className="text-lg text-center text-gray-200">50</td>
                      </tr>
                      <tr className="border-b border-gray-500">
                        <td className="w-1/2 border-r border-gray-500">
                          <div className="text-center py-5">
                            <h4 className="text-xl text-white">Phylisic/Mentalis</h4>
                          </div>
                        </td>
                        <td className="text-lg text-center text-gray-200">50</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div className="w-1/2">
                <div className="p-5 bg-gray-800">
                  <table className="table-auto w-full">
                    <thead className="bg-green-600">
                      <tr className="text-lg text-white text-center"><th className="py-8 font-normal">S. Varinth</th><th className="font-normal">Amount</th></tr>
                    </thead>
                    <tbody className="bg-gray-900">
                      <tr className="border-b border-gray-500">
                        <td className="w-1/2 border-r border-gray-500">
                          <div className="text-center py-5">
                            <p className="text-xl text-white">Kyberos/Phylisic/Sinus</p>
                          </div>
                        </td>
                        <td className="text-lg text-center text-gray-200">10</td>
                      </tr>
                      <tr className="border-b border-gray-500">
                        <td className="w-1/2 border-r border-gray-500">
                          <div className="text-center py-5">
                            <h4 className="text-xl text-white">Kyberos/Mentalis/Timasia</h4>
                          </div>
                        </td>
                        <td className="text-lg text-center text-gray-200">10</td>
                      </tr>
                      <tr className="border-b border-gray-500">
                        <td className="w-1/2 border-r border-gray-500">
                          <div className="text-center py-5">
                            <h4 className="text-xl text-white">Badger/Phylisic/Sinus</h4>
                          </div>
                        </td>
                        <td className="text-lg text-center text-gray-200">10</td>
                      </tr>
                      <tr className="border-b border-gray-500">
                        <td className="w-1/2 border-r border-gray-500">
                          <div className="text-center py-5">
                            <h4 className="text-xl text-white">Badger/Phylisic/Timasia</h4>
                          </div>
                        </td>
                        <td className="text-lg text-center text-gray-200">10</td>
                      </tr>
                      <tr className="border-b border-gray-500">
                        <td className="w-1/2 border-r border-gray-500">
                          <div className="text-center py-5">
                            <h4 className="text-xl text-white">Kyberos/Mentalis/Sinus</h4>
                          </div>
                        </td>
                        <td className="text-lg text-center text-gray-200">10</td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            </div>

            <div className="max-w-full mx-auto">
              <p className="mt-10 mb-6 text-left text-xl text-gray-200">however, each character’s level of training is different as categorized by their individual <strong>‘rare-stars’</strong> all ranging from 1-rare-star to the only 7-rare-star character in existence, the chosen-one which would be created to defeat the evil lord. </p>
            </div>
          </div>

          <div className="max-w-4xl mx-auto text-center">
            <div className="mb-6 max-w-xl mx-auto text-center">
              <h2 className="mt-12 text-3xl text-white font-bold font-heading" id="gap">THE GAP</h2>
            </div>
            <div className="max-w-full mx-auto">
              <p className="mb-6 text-left text-xl text-gray-200">It’s quite simple really, most NFTs has no uniqueness nor utility of any kind, they have no soul that an art should have, and the NFT space is becoming somewhat akin to gambling, we are here to change this.</p>
            </div>
          </div>

          <div className="max-w-4xl mx-auto text-center">
            <div className="mb-6 max-w-xl mx-auto text-center">
              <h2 className="mt-12 text-3xl text-white font-bold font-heading" id="filler">THE FILLER</h2>
            </div>
            <div className="max-w-full mx-auto">
              <h4 className="mb-6 text-left text-2xl text-white font-bold">1) Owners’ exclusivity</h4>
              <p className="mb-6 text-left text-xl text-gray-200">This is an exclusive club of less than 5000 owners. Only these owners would possess the many rights and privileges of being a Sonat holder, the first is that they would be the only ones able to ascend their NFTs to a rarer version of the same NFTs character (i.e., mint higher-rare-stars). The other 5000 NFTs would be minted gradually in seven stages every 10 - 90 days. NFT must have spent a certain number of days (between 10 and 90) in the holder’s wallet, and holders can mint the higher rare-stars at any time on our website once the 10 and 90 day hold conditions are met. This would be embedded in the smart contract, and therefore unchangeable. Since there are fewer higher star characters, their minting would be on a first come first serve basis (depending on the holder that meets the condition and clicks on the mint button first).</p>

              <p className="mb-6 text-left text-xl text-gray-200">All the initial 5000 mints would be 1-rare-stars i.e., they don’t have any level of training yet. When a 1-rare-star character ascends, a 2-rare-star character is formed, and when a 2-rare-star character ascends, a 3-rare-star character is formed and on and on. Periods of Ascension is usually ranges from 10 days for a 1-rare-star to 90 days for the only 7-rare star character in this metaverse.</p>

              <p className="mb-6 text-left text-xl text-gray-200">Schedule for minting superior rare-stars:</p>

              <table className="table-auto w-full" id="schedule-table">
                <thead className="bg-green-600">
                  <tr className="text-lg text-white text-center"><th className="py-8 font-normal">Hold Period</th><th className="font-normal">Ascended (i.e. given up)</th><th className="font-normal">Create (fresh mint)</th></tr>
                </thead>
                <tbody className="bg-gray-900">
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">10 days hold period</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200 border-r border-gray-500">2500 1-Rare-Star characters</td>
                    <td className="text-lg text-center text-gray-200">2500 2-Rare-Star characters</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <h4 className="text-xl text-white">+20 days hold period</h4>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200 border-r border-gray-500">1500 2-Rare-Star characters</td>
                    <td className="text-lg text-center text-gray-200">1500 3-Rare-Star characters</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <h4 className="text-xl text-white">+30 days hold period</h4>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200 border-r border-gray-500">800 3-Rare-Star characters</td>
                    <td className="text-lg text-center text-gray-200">800 4-Rare-Star characters</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <h4 className="text-xl text-white">+60 days hold period</h4>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200 border-r border-gray-500">200 4-Rare-Star characters</td>
                    <td className="text-lg text-center text-gray-200">200 5-Rare-Star characters</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <h4 className="text-xl text-white">+70 days hold period</h4>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200 border-r border-gray-500">20 5-Rare-Star <br /> characters</td>
                    <td className="text-lg text-center text-gray-200">20 6-Rare-Star <br /> characters</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <h4 className="text-xl text-white">+90 days hold period</h4>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200 border-r border-gray-500">one 6-Rare-Star <br /> character</td>
                    <td className="text-lg text-center text-gray-200">one 6-Rare-Star <br /> character</td>
                  </tr>
                </tbody>
              </table>

              <h4 className="mb-6 mt-10 text-left text-2xl text-white font-bold">What does this mean?</h4>

              <p className="mb-6 text-left text-xl text-gray-200">The entire collection apart from their usual rarity is divided into seven stages of additional rarity known as (rare-stars). On the initial mint day, everyone gets a 1-rare-star character, after the first 20days, holders would have the opportunity to ascend their characters (I.e., exchange their current 1-rare-star character for a similar 2-rare-star character). Every time an NFT is traded, the hold period refreshes and starts from zero. The difference between what the holder has before and after is.</p>

              <h4 className="mb-6 mt-10 text-left text-xl text-white font-bold">a) Rarity</h4>


              <ul className="list-inside list-disc text-gray-200 text-xl text-left pl-10">
                <li>Only 5000 1-rare-star characters in existence</li>
                <li>Only 2500 2-rare-star characters in existence</li>
                <li>Only 1500 3-rare-star characters in existence</li>
                <li>Only 750 4-rare-star characters in existence</li>
                <li>Only 220 5-rare-star characters in existence</li>
                <li>Only 29 6-rare-star characters in existence</li>
                <li>Only 1 7-rare-star characters in existence</li>
              </ul>

              <p className="mb-6 mt-10 text-left text-xl text-gray-200">The below table clearly highlight the entire number of each of the characters at every stage of rarity. These numbers are the entire number of characters that would ever exist, as hardcoded in the smart contract.</p>

              <table className="table-auto w-full" id="sonats-breakdown">
                <thead className="bg-green-600">
                  <tr className="text-lg text-white text-center"><th className="py-8 font-normal"></th><th className="font-normal">Kyberos</th><th className="font-normal">Badger</th><th className="font-normal">Philisic</th><th className="font-normal">Mentalis</th><th className="font-normal">Sinus</th><th className="font-normal">Timasia</th><th className="font-normal">Varinths</th><th className="font-normal">S-Variants</th><th className="font-bold">Total</th></tr>
                </thead>
                <tbody className="bg-gray-900">
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">1 rare star</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200">1250</td>
                    <td className="text-lg text-center text-gray-200">1250</td>
                    <td className="text-lg text-center text-gray-200">700</td>
                    <td className="text-lg text-center text-gray-200">700</td>
                    <td className="text-lg text-center text-gray-200">400</td>
                    <td className="text-lg text-center text-gray-200">400</td>
                    <td className="text-lg text-center text-gray-200">250</td>
                    <td className="text-lg text-center text-gray-200">50</td>
                    <td className="text-lg text-center text-gray-200">5000</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">2 rare star</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200">625</td>
                    <td className="text-lg text-center text-gray-200">625</td>
                    <td className="text-lg text-center text-gray-200">350</td>
                    <td className="text-lg text-center text-gray-200">350</td>
                    <td className="text-lg text-center text-gray-200">200</td>
                    <td className="text-lg text-center text-gray-200">200</td>
                    <td className="text-lg text-center text-gray-200">125</td>
                    <td className="text-lg text-center text-gray-200">25</td>
                    <td className="text-lg text-center text-gray-200">2500</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">3 rare star</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200">375</td>
                    <td className="text-lg text-center text-gray-200">375</td>
                    <td className="text-lg text-center text-gray-200">210</td>
                    <td className="text-lg text-center text-gray-200">210</td>
                    <td className="text-lg text-center text-gray-200">120</td>
                    <td className="text-lg text-center text-gray-200">120</td>
                    <td className="text-lg text-center text-gray-200">75</td>
                    <td className="text-lg text-center text-gray-200">15</td>
                    <td className="text-lg text-center text-gray-200">1500</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">4 rare star</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200">188</td>
                    <td className="text-lg text-center text-gray-200">188</td>
                    <td className="text-lg text-center text-gray-200">105</td>
                    <td className="text-lg text-center text-gray-200">105</td>
                    <td className="text-lg text-center text-gray-200">60</td>
                    <td className="text-lg text-center text-gray-200">60</td>
                    <td className="text-lg text-center text-gray-200">38</td>
                    <td className="text-lg text-center text-gray-200">6</td>
                    <td className="text-lg text-center text-gray-200">750</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">5 rare star</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200">55</td>
                    <td className="text-lg text-center text-gray-200">55</td>
                    <td className="text-lg text-center text-gray-200">30</td>
                    <td className="text-lg text-center text-gray-200">30</td>
                    <td className="text-lg text-center text-gray-200">18</td>
                    <td className="text-lg text-center text-gray-200">18</td>
                    <td className="text-lg text-center text-gray-200">11</td>
                    <td className="text-lg text-center text-gray-200">3</td>
                    <td className="text-lg text-center text-gray-200">220</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">6 rare star</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200">6</td>
                    <td className="text-lg text-center text-gray-200">6</td>
                    <td className="text-lg text-center text-gray-200">4</td>
                    <td className="text-lg text-center text-gray-200">3</td>
                    <td className="text-lg text-center text-gray-200">3</td>
                    <td className="text-lg text-center text-gray-200">3</td>
                    <td className="text-lg text-center text-gray-200">2</td>
                    <td className="text-lg text-center text-gray-200">2</td>
                    <td className="text-lg text-center text-gray-200">29</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">7 rare star</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200">1</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">Total</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200"></td>
                    <td className="text-lg text-center text-gray-200 font-bold">10000</td>
                  </tr>
                </tbody>
              </table>

              <h4 className="mb-6 mt-10 text-left text-xl text-white font-bold">b) Game Strength</h4>

              <p className="mb-6 mt-10 text-left text-xl text-gray-200">A higher rare-star character is significantly stronger than a lower rare-star character in the game and would be able to defeat more formidable enemies from the evil lord. <br /> <br /> Higher rare-stars can only be minted if the token has been held in a particular wallet for the necessary period, qualifying the holder to mint the next level of rare-star character. </p>

              <h4 className="mb-6 text-left text-2xl text-white font-bold">2)	Deflationary:</h4>

              <p className="mb-6 text-left text-xl text-gray-200">You may ask, what happens to the lower rare-stars returned? For the first two sets, these would be split in to two. One half would be auctioned off so that other members could join in on the project, and the other half would be burned forever, no matter their value.</p>

              <table className="table-auto w-full" id="sonats-breakdown">
                <thead className="bg-green-600">
                  <tr className="text-lg text-white text-center"><th className="py-8 font-normal">Wait Period</th><th className="font-normal">Ascended (i.e., given up)</th><th className="font-normal">Auctioned back </th><th className="font-normal">Burnt forever</th></tr>
                </thead>
                <tbody className="bg-gray-900">
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">10 days hold</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200">2500 1-Rare-Star characters</td>
                    <td className="text-lg text-center text-gray-200">1250 1-Rare-Star</td>
                    <td className="text-lg text-center text-gray-200">1250 1-Rare-Star</td>
                  </tr>
                  <tr className="border-b border-gray-500">
                    <td className="border-r border-gray-500">
                      <div className="text-center py-5">
                        <p className="text-xl text-white">+20 days hold</p>
                      </div>
                    </td>
                    <td className="text-lg text-center text-gray-200">1500 2-Rare-Star characters</td>
                    <td className="text-lg text-center text-gray-200">750 2-Rare-Star</td>
                    <td className="text-lg text-center text-gray-200">750 2-Rare-Star</td>
                  </tr>
                </tbody>
              </table>


              <p className="mb-6 mt-5 text-left text-xl text-gray-200">From this table, it means that the project is designed to burn a total of 2000 characters, some insanely rare ones at that no matter the price over the period of the first 30 days after mint day. This is hardcoded into the smart contract, and available on GitHub for public review.</p>

              <h4 className="mb-6 text-left text-2xl text-white font-bold">3)	Utility:</h4>

              <p className="mb-6 text-left text-xl text-gray-200">Holders of the Sonat clan would be able to loan out their characters to gamers in the upcoming P2E game; Sonat World, while they wait to upgrade their character to a higher rare-star. Only the 8000 NFT characters left would be able to advance and effectively play the game, non-holders of the Sonat NFT would have to loan it from holders and in turn share their P2E income with the owner of the NFT that is being used. This means that you are staking your NFT to earn passive income while you wait to get a rarer one. The Holder would earn a percentage of whatever the gamer earns during gameplay. This greatly benefits the gamer also, as they have access to stronger/rarer characters with certain strengths required to defeat whatever roadblock they are experiencing in the game. Holders of the NFT would be able to lend their characters to more than one gamer depending on the total number of gamers playing Sonat World.</p>




            </div>
          </div>

          <div className="max-w-4xl mx-auto text-center">
            <div className="mb-6 max-w-xl mx-auto text-center">
              <h2 className="mt-12 text-3xl text-white font-bold font-heading" id="roadmap">ROADMAP</h2>
            </div>
            <div className="max-w-full mx-auto">

              <ul className="list-inside list-disc text-gray-200 text-xl text-left pl-10">
                <li>Phase 1 (Q4 – 2021):
                  <ul className="list-inside list-disc text-gray-200 text-xl text-left pl-10">
                    <li>Release of the first 5000 1 – star Sonat clan characters</li>
                    <li>Game teasers</li>
                    <li>Burning of 1250 1-rare-star and 750 2-rare-star characters</li>
                  </ul>
                </li>

                <li className="mt-10">Phase 2 (Q1 – 2022):
                  <ul className="list-inside list-disc text-gray-200 text-xl text-left pl-10">
                    <li>Game teasers, testing and airdrops</li>
                    <li>Market place for staking NFTs opens on the website.</li>
                  </ul>
                </li>

                <li className="mt-10">Phase 3 (Q2 – 2022):
                  <ul className="list-inside list-disc text-gray-200 text-xl text-left pl-10">
                    <li>Game release, and token launch on suitable launchpad</li>
                    <li>Holders of Sonat clan NFTs can start earning passive income by lending them to gamers</li>
                    <li>Sonat token lists on major exchanges</li>
                  </ul>
                </li>

                <li className="mt-10">Phase 4 (Q4 – 2022):
                  <ul className="list-inside list-disc text-gray-200 text-xl text-left pl-10">
                    <li>Target for gameplay to reach one million gamers, meaning each sonat can be loaned out to an average of 125 players, each holder earns passive income from each player</li>
                  </ul>
                </li>
              </ul>

            </div>
          </div>


        </div>
      </section>

      <Footer />
    </div>

    
  );
}

export default Intro;
