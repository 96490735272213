/* eslint-disable no-undef */
import React from 'react'
import { Helmet } from 'react-helmet'

export function SwiperDemo({ activeIndex, swiper, setActiveIndex, loadScript }) {
    return (
        <div>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://unpkg.com/swiper/swiper-bundle.min.css"
                />
            </Helmet>
            <div className="swiper mySwiper">
                <div className="swiper-wrapper">
                    <div className="swiper-slide"><img className="h-126 object-contain w-full" src="images/Badger.png" alt="" /></div>
                    <div className="swiper-slide"><img className="h-90 object-contain w-full" src="images/Kyberos.png" alt="" /></div>
                    <div className="swiper-slide"><img className="h-90 object-contain w-full" src="images/Phylisic.png" alt="" /></div>
                    <div className="swiper-slide"><img className="h-90 object-contain w-full" src="images/Mentalis.png" alt="" /></div>
                    <div className="swiper-slide"><img className="h-90 object-contain w-full" src="images/Timasia.png" alt="" /></div>
                    <div className="swiper-slide"><img className="h-90 object-contain w-full" src="images/Sinus.png" alt="" /></div>
                    <div className="swiper-slide"><img className="h-90 object-contain w-full" src="images/V1.png" alt="" /></div>
                    <div className="swiper-slide"><img className="h-90 object-contain w-full" src="images/Sv1.png" alt="" /></div>
                </div>
            </div>
        </div>
    )
}
