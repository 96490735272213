import React from "react";

function Intro() {

  return (
    <section className="2xl:py-10 overflow-hidden bg-gray-800">
      <div className="container px-4 mx-auto mt-8">
        <div className="max-w-4xl mx-auto text-center">
          <span className="font-bold text-green-600">Meet the most prolific warriors by entering the sonat clan metaverse..</span>
          <h2 className="my-8 text-white font-bold font-heading text-4xl">A diverse clan of peaceful sonats who would fiercely defend their kingdom against invading threats from a scary dude known as the evil lord</h2>
        </div>
      </div>
    </section>
  );
}

export default Intro;
