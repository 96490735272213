import { Disclosure } from "@headlessui/react";
import React, { useState } from "react";

const FAQ_COL1 = [
  {
    title: 'What is the Sonat Clan project all about?',
    desc: 'The Sonat Clan is a collection of amazing NFTs based on medieval characters in an upcoming play to earn game scheduled to launch in Q1 2022.'
  },
  {
    title: 'Where can I participate in the initial mint?',
    desc: 'The initial mint would be done on our website, https://sonatclan.com, please follow us on twitter, and discord to stay up to date on upcoming events.'
  },
  {
    title: 'Would there be a pre-sale, and how do I get whitelisted for it?',
    desc: 'Yes, there would be a pre-sale one week before the official mint date, details on how to qualify for this would be posted on our twitter and discord pages.'
  },
  {
    title: 'What can I do with my Sonat NFT?',
    desc: 'You can trade/flip your NFTS, but better than that, you can hold and upgrade your sonat to a much rarer and stronger character every 10 to 90 days depending on your character’s rare-star. Lastly, you would be able to stake your NFTs when our P2E game is launched and lend to millions of gamers all over the world and earn passive income.'
  },
  {
    title: 'What are rare-stars?',
    desc: 'Rare-stars depicts the rank of each Sonat NFT character in the metaverse. The higher the rank, the rarer and stronger the characters are. Rare-stars goes from one-rare-star to seven-rare-star. You can see the different rarity of each <a href="/whitepaper#sonats-breakdown" class="font-bold">here</a>.'
  },
  {
    title: 'How can I increase the rarity of my NFT?',
    desc: 'Only by holding your NFT for the predetermined time would qualify you to upgrade and mint higher rare-star and stronger characters. See the upgrade schedule <a href="/whitepaper#schedule-table" class="font-bold">here</a>.'
  },
  // Hidden
  {
    title: 'How many characters are present at each level of rare-stars?',
    desc: 'There are 5000 one-rare-star characters, 2500 two-rare-stars, 1,500 three-rare-stars, 700 four-rare-stars, 220 five-rare-stars, 29 six-rare-stars, and only 1 seven-rare-star character.'
  },
  {
    title: 'Am I guaranteed of an upgrade if I hold my NFT for the predetermined time?',
    desc: 'Unfortunately, because of the reducing number of characters at higher rare-star levels, we would not be able to guarantee anyone of an upgrade, it would have to be on a first come, first serve basis. However, the more other people trade their NFTs, the fewer the competition to get upgraded to higher rare-stars.'
  },
  {
    title: 'I purchased an NFT that has been held for 9 days, I just have to hold for one more day to qualify for an upgrade, right?',
    desc: 'No, the hold time is tied to each holder, and not the character. Every time a character is traded, the holder changes, therefore the hold time refreshes and starts from 0 days.'
  },
  {
    title: 'Do I get an additional NFT while trying to upgrade?',
    desc: 'No, you would give up what you currently hold for the upgraded rarer and stronger version of your NFT.'
  },
  {
    title: 'What would be done to the characters turned in for an upgrade?',
    desc: 'The first 4000 characters turned in for an upgrade would be divided into two, 2000 would be burned no matter the value of those characters at the time, and the other 2000 would be auctioned back into the community.'
  },
  {
    title: 'How can I know how rare my NFT is?',
    desc: 'You can use the rarity calculator on our website to know how rare your NFT is compared with others.'
  },
  {
    title: 'What if I don’t want to change my character because I am attached to it?',
    desc: 'Although you would be getting a different NFT when you upgrade, the character would look a bit similar to the previous one. Only attributes showcasing the bragging rights of a higher rank would be visibly different.'
  },
  {
    title: 'When would I be able to use and stake my NFT for the game?',
    desc: 'According to our roadmap, this is scheduled for Q1 2022.'
  }
]

function createMarkup(content) {
  return {
    __html: content
  };
};

function FAQ() {
  const visibleTillIndex = 5;
  const [visible, setVisibleCount] = useState(visibleTillIndex)

  const handleShowAllFAQ = () => {
    if (visible === FAQ_COL1.length) {
      setVisibleCount(visibleTillIndex)
    } else {
      setVisibleCount(FAQ_COL1.length)
    }
  }
  return (
    <section className="relative bg-gray-800 overflow-hidden" id="faq-section">
      <img className="block lg:hidden z-10 w-3/4 h-full m-auto object-cover" src="sonats-gif.gif" alt="" />
      <img className="hidden lg:block absolute z-10 top-0 left-0 w-2/6 h-full object-cover" src="sonats-gif.gif" alt="" />
      <img className="hidden lg:block absolute bottom-0 left-0 h-128 -mb-64 ml-64" src="zospace-assets/lines/circle.svg" alt="" />
      <div className="relative w-full lg:w-4/6 ml-auto pl-4 lg:pl-24 pr-4 pt-20">
        <div className="mb-20">
          <span className="text-lg font-bold text-green-600">Hey! Have any questions?</span>
          <h2 className="mt-8 text-7xl text-white font-bold font-heading">FAQ's</h2>
        </div>


        <div className="max-w-4xl">
          <div className='w-full'>
            <div className="max-w-screen-xl mx-auto px-4">
              <div className="-mx-4 flex flex-wrap">
                {
                  FAQ_COL1.map((e, i) => {
                    return (
                      <div className="w-full p-4 sm:w-1/1 lg:w-1/2">
                        <SingleFaq visible={visible} title={e.title} desc={e.desc} index={i} />
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="text-center">
            <button onClick={handleShowAllFAQ} className="inline-block px-12 py-5 text-white font-bold rounded-full transition duration-200 bg-green-600 hover:bg-green-700" to="/whitepaper">
              {visible === FAQ_COL1.length ? 'Show Less' : 'View all FAQ'}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export const SingleFaq = ({ index, title, desc, visible }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onDisclosureClick = () => {
    setIsOpen(!isOpen);
  }
  if (index > visible) {
    return <></>
  }
  return (
    <Disclosure as="div" className="mb-4 py-10 px-8 bg-gray-700 rounded-xl" >
      <Disclosure.Button as='button' className="py-2 text-white">
        <button className="flex flex-wrap -mx-1 w-full text-left" onClick={onDisclosureClick}>
          <div className="w-1/4 px-1">
            <span className="flex items-center justify-center w-12 h-12 text-lg font-bold bg-green-600 hover:bg-green-700 rounded-full text-white">{index + 1}</span>
          </div>
          <div className="w-3/4 px-1">
            <div className="flex items-center">
              <h3 className="text-xl text-white font-bold">{title}</h3>
              <span className="pl-4">
                <svg className={`w-4 h-4 ${isOpen ? 'faq-close-button' : 'faq-open-button'}`}
                  width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.18267 9.00018C0.910673 9.26818 0.473672 9.26818 0.203672 9.00018C-0.0663284 8.73218 -0.0673279 8.29918 0.203672 8.03118L8.11167 0.201183C8.38167 -0.0668173 8.81867 -0.0668173 9.09067 0.201183L16.9987 8.03118C17.2687 8.29918 17.2687 8.73218 16.9987 9.00018C16.7277 9.26818 16.2897 9.26818 16.0197 9.00018L8.60067 1.85918L1.18267 9.00018Z" fill="#1F40FF"></path></svg>
              </span>
            </div>
          </div>
        </button>
      </Disclosure.Button>
      <Disclosure.Panel className="text-white">
        <div className="flex w-full items-center">
          <div className="w-1/4 px-1">
            <div className="mt-8 ml-6 inline-block lg:h-20 w-px bg-gray-500"></div>
          </div>
          <p className="w-3/4 text-lg text-gray-300" dangerouslySetInnerHTML={createMarkup(desc)}></p>
        </div>
      </Disclosure.Panel>
    </Disclosure >
  )
}

export default FAQ;
