import React from "react";

function Team() {

  return (
    <section className="relative py-20 2xl:py-40 bg-gray-800 overflow-hidden" id="team-section"><img className="hidden lg:block absolute inset-x-0 bottom-0 mb-96" src="zospace-assets/lines/line-two-montain.svg" alt="" /><div className="relative container px-4 mx-auto">
          <div className="max-w-4xl mx-auto">
            <h2 className="mb-24 text-center text-5xl font-bold font-heading text-white">Our Team</h2>
            <div className="flex flex-wrap justify-center -mx-10 mb-20">
              <div className="w-full lg:w-1/3 px-10 mb-20 lg:mb-0">
                <div className="lg:mt-32">
                  <img className="mb-8 filter grayscale team-image w-full h-112 lg:h-80 object-cover object-top" src="images/V5.png" alt="" /><a className="inline-block w-full p-10 rounded-lg transition duration-200 bg-green-600 hover:bg-green-700" href="/">
                    <h3 className="mb-2 text-3xl text-white font-bold font-heading">Duke Sonatera</h3>
                    <p className="mb-4 text-lg text-white ">Thinks he’s the smartest, none of us agrees</p>
                  </a>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-10 mb-20 lg:mb-0">
                <div>
                  <img className="mb-8 filter grayscale team-image w-full h-112 lg:h-80 object-cover object-top" src="images/SV3.png" alt="" /><a className="inline-block w-full p-10 rounded-lg transition duration-200 bg-green-600" href="/">
                    <h3 className="mb-2 text-3xl text-white font-bold font-heading">SonaLord</h3>
                    <p className="mb-4 text-lg text-white">This guy is too nitpicky about everything; I don’t know how we all could put up with him</p>
                  </a>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-10">
                <div className="lg:mt-32">
                  <img className="mb-8 filter grayscale team-image w-full h-112 lg:h-80 object-cover object-top" src="images/Sinus.png" alt="" /><a className="inline-block w-full p-10 rounded-lg transition duration-200 bg-green-600 hover:bg-green-700" href="/">
                    <h3 className="mb-2 text-3xl text-white font-bold font-heading">SoKnight</h3>
                    <p className="mb-4 text-lg text-white">Takes sh*t from everyone, and we always have more to give</p>
                  </a>
                </div>
              </div>
              <div className="w-full lg:w-1/3 px-10  lg:mb-0">
                <div>
                  <img className="mb-8 filter grayscale team-image w-full h-112 lg:h-80 object-cover object-top" src="images/Badger.png" alt="" /><a className="inline-block w-full p-10 rounded-lg transition duration-200 bg-green-600 hover:bg-green-700" href="/">
                    <h3 className="mb-2 text-3xl text-white font-bold font-heading">SonArtist</h3>
                    <p className="mb-4 text-lg text-white ">What can we say, dude’s a genius, and he likes bragging about it</p>
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="text-center"><a className="inline-block py-4 px-12 border border-gray-200 hover:border-gray-50 rounded-full font-bold text-white" href="/">More</a></div> */}
          </div>
        </div>
      </section>
  );
}

export default Team;
