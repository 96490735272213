import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { WhitelistModal } from "./Modal";
import HeadShot from '../assets/sonat-headshots.gif';
import { Popover, Transition } from '@headlessui/react'
import {
  XIcon,
} from '@heroicons/react/outline'
import { HashLink } from 'react-router-hash-link';

const options = [
  {
    name: 'Sonats',
    href: '/#sonats-section',
  },
  {
    name: 'FAQ',
    href: '/#faq-section',
  },
  {
    name: 'Roadmap',
    href: '/whitepaper#roadmap',
  },
  {
    name: 'Team',
    href: '/#team-section',
  }
]

function EthmsNavbar() {
  const [open, setOpen] = useState(false)
  const handleWhiteList = () => {
    setOpen(true)
  }

  const title=`VIP Area coming soon`;
  const body=`You'll need to own a Sonat Clan NFT to access the VIP Area`;

  const handleVip = () => {
    setOpen(true)
  }

  return (
    <Popover className='py-8 px-4 lg:px-10 bg-gray-800'>
      <nav className="relative flex justify-between items-center">
        <WhitelistModal open={open} setOpen={setOpen} title={title} body={body} buttonText='Ok'></WhitelistModal>
        <Link className="text-2xl text-white font-bold" to="/">
          <img src={HeadShot} className='rounded-full inline h-12 w-12 mr-2' alt='sonat' />
          Sonat Clan
        </Link>
        <div className="-mr-2 -my-2 lg:hidden">
          <Popover.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <svg className="w-10 h-3" width="39" height="13" viewBox="0 0 39 13" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="39" height="2" rx="1" fill="#C4C4C4"></rect><rect x="19" y="11" width="20" height="2" rx="1" fill="#C4C4C4"></rect></svg>
          </Popover.Button>
        </div>

        <div className="hidden lg:block ml-auto mr-16">
          <ul className="flex items-center text-white space-x-10">
            {
              options.map((e, i) => {
                return (
                  <Fragment key={i}>
                    <li><HashLink className="text-white font-bold text-lg" to={e.href}>{e.name}</HashLink></li>
                    {
                      (i !== options.length-1) &&
                      <span>
                        <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="2.5" cy="2.5" r="2.5" fill="#726B6B"></circle></svg>
                      </span>
                    }
                  </Fragment>
                )
              })
            }
          </ul>
        </div>

        <div className="hidden lg:block">
          <Link onClick={handleVip} className="inline-block px-12 py-4 text-white font-bold border bg-gradient-to-r from-green-400 to-green-600 border-gray-200 hover:border-white rounded-full" to="/">
            VIP Section
          </Link>
        </div>
      </nav>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel style={{ zIndex: '100' }} focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-gray-800 divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Link className="text-2xl text-white font-bold" to="/">
                    <img src={HeadShot} className='rounded-full inline h-12 w-12 mr-2' alt='sonat' />
                    Sonat Clan
                  </Link>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-gray-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {options.map((item) => (
                    <HashLink
                      key={item.name}
                      to={item.href}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <span className="ml-3 text-base font-medium text-white">{item.name}</span>
                    </HashLink>
                  ))}
                  <button className='text-green-600 font-bold' onClick={handleWhiteList} >
                    Get Whitelisted
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default EthmsNavbar;
