import React from "react";
import { HashLink } from "react-router-hash-link";

function HowItWorks() {

  return (
    <section className="py-20 2xl:py-40 bg-gray-800 overflow-hidden">
      <div className="container px-4 mx-auto">
        <div className="max-w-2xl mx-auto text-center mb-6">
          <span className="text-lg font-bold text-green-600">How it works</span>
          <h2 className="mt-12 text-5xl lg:text-6xl text-white font-bold font-heading">Your Sonat evolves &amp; becomes rarer over time </h2>
        </div>
        {/* <div className="relative px-8 lg:pr-0 lg:pl-12 2xl:pl-24 mb-14 lg:mb-40 bg-gray-800 rounded-xl overflow-hidden">
          <div className="w-full lg:w-1/3 pt-12 lg:py-12">
            <h3 className="mb-4 text-xl font-bold text-white">How it work? Steps</h3>
            <p className="text-xl text-gray-300">Your Sonat NFT can be upgraded for rarer traits (limited count) if you hodl on to it, on a first come first serve basis.</p>
          </div>
          <img className="lg:absolute top-0 right-0 bottom-0 h-full w-full lg:w-1/3 object-cover" src="zospace-assets/images/illustration-men-phone.svg" alt="" />
        </div> */}
        <YoutubeEmbed />
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/cKky4WLoYBw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

        <div className="relative flex flex-wrap justify-center -mx-10 mb-20">
          <img className="hidden lg:block absolute inset-y-0 -mr-80 -mt-16" src="zospace-assets/lines/dots-gray.svg" alt="" /><div className="w-full lg:w-1/3 px-10 mb-20 lg:mb-0">
            <div className="relative flex">
              <img className="hidden lg:block absolute top-0 right-0 -mr-20 -mt-16" src="zospace-assets/lines/dots-gray.svg" alt="" /><div className="mr-8">
                <span className="flex justify-center items-center w-14 h-14 text-white text-lg font-bold rounded-full bg-green-600">1</span>
              </div>
              <div className="max-w-xs">
                <h3 className="mb-6 text-lg font-bold font-heading text-white">Owners exclusivity</h3>
                <p className="text-lg text-gray-200">Initial public mint of 5000 sonats, only the holders of a sonat can mint the next rarer and more powerful 5000 sonats</p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 px-10 mb-20 lg:mb-0">
            <div className="flex">
              <div className="mr-8">
                <span className="flex justify-center items-center w-14 h-14 text-white text-lg font-bold rounded-full bg-green-600">2</span>
              </div>
              <div className="max-w-xs">
                <h3 className="mb-6 text-lg font-bold font-heading text-white">Utility</h3>
                <p className="text-lg text-gray-200">Only the holders of the sonat NFT would be able to play the upcoming P2E game unhindered, every holder however can stake their sonat and lend to thousands of gamers, and earn passive income.</p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/3 px-10">
            <div className="flex">
              <div className="mr-8">
                <span className="flex justify-center items-center w-14 h-14 text-white text-lg font-bold rounded-full bg-green-600">3</span>
              </div>
              <div className="max-w-xs">
                <h3 className="mb-6 text-lg font-bold font-heading text-white">Deflationary</h3>
                <p className="text-lg text-gray-200">A total of 2000 one-rare-star and two-rare-star sonats set to be burned after 30+ days of launch irrespective of their value</p>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center">
          <HashLink className="inline-block px-12 py-5 text-white font-bold rounded-full transition duration-200 bg-green-600 hover:bg-green-700" to="/whitepaper#filler">Read More</HashLink>
        </div>
      </div>
    </section>
  );
}


const YoutubeEmbed = () => (
  <div className="video-responsive">
    <iframe
      // width="853"
      // height="480"
      src={`https://www.youtube.com/embed/cKky4WLoYBw`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen
      title="Sonat Intro"
    />
  </div>
);


export default HowItWorks;
