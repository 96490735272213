/* eslint-disable no-undef */
import React, { useLayoutEffect, useState } from "react";
import { SwiperDemo } from './SwiperDemo'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'

let swiper;
function loadScript(src, cb, setLoader) {
  return new Promise(function (resolve, reject) {
    const script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', function () {
      setLoader(false)
      // setTimeout(() => {
      resolve();
      swiper = new Swiper(".mySwiper", {
        effect: "cards",
        grabCursor: true,
        loop: true,
        loopFillGroupWithBlank: true,
      });
      swiper.on('activeIndexChange', cb);
      // });
    });
    script.addEventListener('error', function (e) {
      reject(e);
    });
    document.body.appendChild(script);
  })
};


export const PROFILE_CONTENT = [
  {
    name: "Badger",
    desc: "Meet the bad-ass <strong>Badger</strong>: <br/> <br/> Thick skinned, thick armor, defense centric warrior that’s a nightmare for any opponent.",
  },
  {
    name: "Kyberos",
    desc: "Check out the ferocious <strong>Kyberos</strong>: <br/> <br/> These funky clan members are the most combative and agile. They launch multiple attacks simultaneously overwhelming anyone unfortunate enough to stand against them",
  },
  {
    name: "Phylisic",
    desc: "And the vicious <strong>Phylisic</strong>: <br/> <br/> Master of long-range attacks, how do you even get close to these degens?",
  },
  {
    name: "Mentalis",
    desc: "Meet this smart ass called the <strong>Mentalis</strong>: <br /> <br /> Not as crazy as the first 3, but knows how to outsmart everyone, and what exactly to do to win a fight, sometimes by just running away",
  },
  {
    name: "Timasia",
    desc: "And this super sonat; the <strong>Timasia</strong>: <br /> <br /> The master of time, they stop time or slow down time enough to attack and eviscerate their enemies. Only an enemy with the powers of a timasia can resist this attack.",
  },
  {
    name: "Sinus",
    desc: "And check out this psychopath called the <strong>Sinus</strong>: <br /> <br /> The grand wizards/witches of the metaverse, although not as strong as the more physical sonats, they however can suck out the life force of an enemy to death using their sparkly magical orb, they also heal up pretty quickly.",
  },
  {
    name: "Varinths",
    desc: "The Varinths <strong>Varinths</strong>: <br /> <br /> These sonats are born with more than one trait in one, an example of a phenomenal varinths is the Kyberos/Sinus Varinth:  Being able to function and has all the abilities of a kyberos and a sinus in one sonat.",
  },
  {
    name: "S-Varinths",
    desc: "The Super <strong>Varinths</strong>: <br /> <br /> And as if that wasn’t enough, some are born with three traits in one, these are the Super Varinths, here for example is a Badger/Phylisic and Timasia Varinth. These are super rare, and they possess unimaginable power in the metaverse.",
  },
];

function createMarkup(content) {
  return {
    __html: content
  };
};

function SonatsProfileWithSlider() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loader, setLoader] = useState(true)
  const onSlideChange = (change) => {
    console.log('swiper', swiper)
    const min = 1;
    const max = PROFILE_CONTENT.length;
    let index = change.activeIndex;
    if (index < min) {
      index = max;
      swiper.slideTo(max)
    }
    if (index > max) {
      index = min;
      swiper.slideTo(min)
    }
    setActiveIndex(index - 1);
  }

  useLayoutEffect(() => {
    loadScript('https://unpkg.com/swiper/swiper-bundle.min.js', onSlideChange, setLoader);
  }, [])

  const onNextHandler = () => {
    swiper.slideNext()
  }
  const onPrevHandler = () => {
    swiper.slidePrev()
  }
  return (
    <section className="bg-gray-800 overflow-hidden" id="sonats-section">
      <div className="container mx-auto px-4">
        <div className="max-w-xl mx-auto mb-6 text-center">
          <span className="text-lg text-green-600 font-semibold">Our Warriors</span>
          <h2 className="mt-8 text-5xl text-white font-bold font-heading">Sonat Warriors</h2>
        </div>
        <div className="flex flex-wrap -mx-5">

          <div className="w-full px-5">
            <div className="flex flex-wrap -px-4">
              <div className="w-full lg:w-2/3 md:px-24 px-10 mt-10">
                {
                  !loader &&
                  <SwiperDemo swiper={swiper} activeIndex={activeIndex} setActiveIndex={setActiveIndex}></SwiperDemo>
                }
              </div>

              <div className="w-full lg:w-1/3 p-4 align-bottom flex justify-around	flex-col">
                <p className="text-3xl text-center pt-8 lg:pt-44 text-gray-300 profile-desc order-last lg:order-first"
                  dangerouslySetInnerHTML={createMarkup(PROFILE_CONTENT[activeIndex]?.desc)}
                >
                </p>
                <div className='flex mt-8 lg:mt-4'>
                  <div onClick={onPrevHandler} className="cursor-pointer mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-green-600 mx-0 h-10 w-10">
                    <ChevronLeftIcon className="h-8 w-8 text-white" aria-hidden="true" />
                  </div>
                  <div onClick={onNextHandler} className="cursor-pointer mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-green-600 mx-0 h-10 w-10">
                    <ChevronRightIcon className="h-8 w-8 text-white" aria-hidden="true" />
                  </div>
                </div>
                {/* <button className='inline-block mt-8 lg:mt-0 mb-0 sm:mb-0 sm:mr-4 py-5 px-12 text-white font-bold rounded-full transition duration-200 bg-green-600 hover:bg-green-700' style={{ color: 'white' }} onClick={onClickHandler}>
                  Next
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SonatsProfileWithSlider;
