import { Helmet } from "react-helmet";
import Navbar from './Components/Navbar';
import Hero from './Components/Hero';
import Intro from './Components/Intro';
// import SonatsProfile from './Components/SonatsProfile';
import SonatsProfileWithSlider from './Components/SonatsProfileSlider';
import HowItWorks from './Components/HowItWorks';
import Masterplan from './Components/Masterplan';
import FAQ from './Components/FAQ';
import Team from './Components/Team';
import Footer from './Components/Footer';
import Whitepaper from './Pages/whitepaper';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

function App() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sonat Club NFT</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="" />
        <meta name="author" content="" />
        {/* <script
          src="assets/vendor/bootstrap/dist/js/bootstrap.bundle.min.js"
          type="text/javascript"
        /> */}
      </Helmet>

      <Router>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/whitepaper" exact>
            <Whitepaper />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

function Home() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Intro />
      {/* <SonatsProfile /> */}
      <SonatsProfileWithSlider />
      <HowItWorks />
      <Masterplan />
      <FAQ />
      <Team />
      <Footer />
    </div>
  );
}

export default App;
