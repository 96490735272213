import React from "react";
import { HashLink } from "react-router-hash-link";

function Masterplan() {

  return (
    <section className="relative bg-gray-800 overflow-hidden" id="masterplan-section">
      <img className="hidden lg:block absolute top-0 left-0 -ml-96 -mt-40" src="zospace-assets/lines/circle.svg" alt="" /><img className="hidden lg:block absolute bottom-0 right-0 -mr-32 -mb-72" src="zospace-assets/lines/circle.svg" alt="" />
      <div className="mb-6 max-w-xl mx-auto text-center">
        <span className="text-lg font-bold text-green-600">Our masterplan</span>
        <h2 className="mt-12 text-5xl lg:text-6xl text-white font-bold font-heading">The Sonat Project </h2>
      </div>
      <div className="relative container px-4 mx-auto mb-20">
        <div>
          <div className="flex flex-wrap -m-6">
            <div className="w-full md:w-1/2 lg:w-1/3 p-6">
              <div className="p-16 bg-gray-800 rounded-lg">
                <h3 className="mb-6 font-bold font-heading text-green-600 text-2xl text-center">Mighty Sonat Heroes</h3>
                <p className="text-lg text-gray-200 text-center">NFT characters are based on the medieval characters of an upcoming game, on our roadmap, and each of them vary in rarity.</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-6">
              <div className="p-16 bg-gray-800 rounded-lg">
                <h3 className="mb-6 font-bold font-heading text-green-600 text-2xl text-center">Increase your Sonat's rarity over time</h3>
                <p className="text-lg text-gray-200 text-center">In addition to the individual rarity, the NFTs can increase in rarity based on conditions set in the smart contract (known as rare-stars).</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-6">
              <div className="p-16 bg-gray-800 rounded-lg">
                <h3 className="mb-6 font-bold font-heading text-green-600 text-2xl text-center">5000 initial mints</h3>
                <p className="text-lg text-gray-200 text-center">First 5000 to be minted initially are all 1-rare-star characters. Higher rare-star characters can only be minted by the initial holders in subsequent scheduled dates.</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-6">
              <div className="p-16 bg-gray-800 rounded-lg">
                <h3 className="mb-6 text-2xl font-bold font-heading text-green-600 text-center">Higher rare-star, Stronger Character</h3>
                <p className="text-lg text-gray-200 text-center">The higher the rare-star, that rarer the character, and the stronger they are in the metaverse.</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-6">
              <div className="p-16 bg-gray-800 rounded-lg">
                <h3 className="mb-6 text-2xl font-bold font-heading text-green-600 text-center">Upgrade your Sonat: First come, first serve</h3>
                <p className="text-lg text-gray-200 text-center">TThe NFT must be held in a wallet for between 10 to 90 days to qualify for an upgrade, depending on the higher rare-star being minted.</p>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 p-6">
              <div className="p-16 bg-gray-800 rounded-lg">
                <h3 className="mb-6 text-2xl font-bold font-heading text-green-600 text-center">Game with your NFT and lend it</h3>
                <p className="text-lg text-gray-200 text-center">Holders can use and lend their NFT characters simultaneously and automatically earn a percentage of what the borrower makes.</p>
              </div>
            </div>
          </div>
          <div className="text-center"><HashLink className="inline-block px-12 py-5 text-white font-bold rounded-full transition duration-200 bg-green-600 hover:bg-green-700" to="/whitepaper#schedule-table">View upgrade schedule</HashLink></div>
        </div>
      </div>
    </section>
  );
}

export default Masterplan;
